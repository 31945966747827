import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CreateAtomPayload, documentService, UpdateAtomPayload } from './DocumentService';
import { queryClient, queryKey } from '../utils/query-client';
import { Atom, CodeGenDebug, Tag } from '../types/RealtaDocument';

export function useGetAllDocumentMeta(data: {
  roles?: string[];
  datasets?: string[];
  jurisdictions?: string[];
  title?: string;
}) {
  const query = useQuery(
    ['getAllDocumentMeta'],
    async () => {
      return await documentService.getAllDocumentMeta(data);
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return query;
}

export function useGetDocumentsOfFolder(folderUuid: string) {
  const query = useQuery(
    ['getDocumentsOfFolder'],
    async () => {
      return await documentService.getDocumentsOfFolder(folderUuid);
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return query;
}

export function useGetDocumentSectionsByPage(data: { documentUuid: string; page: number }) {
  const query = useQuery(
    ['getDocumentSectionsByPage'],
    async () => {
      return await documentService.getDocumentSectionsByPage(data);
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return query;
}

export function useGetDocumentHeadingSections(data: { documentUuid: string }) {
  const query = useQuery(
    ['getDocumentHeadingSections'],
    async () => {
      return await documentService.getDocumentHeadingSections(data);
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return query;
}

export function useGetAtoms(codeBlockId?: string) {
  const query = useQuery(
    [queryKey.atoms, codeBlockId],
    async () => {
      return await documentService.getAtoms({ codeBlockId });
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return query;
}

export function useCreateAtom(options?: UseMutationOptions<Atom, unknown, CreateAtomPayload, unknown>) {
  return useMutation(async (data: CreateAtomPayload) => {
    const atom = await documentService.createAtom(data);
    queryClient.invalidateQueries({ queryKey: [queryKey.atoms] });
    return atom;
  }, options);
}

export function useUpdateAtom(options?: UseMutationOptions<Atom, unknown, UpdateAtomPayload, unknown>) {
  return useMutation(async (data: UpdateAtomPayload) => {
    const atom = await documentService.updateAtom(data);
    queryClient.invalidateQueries({ queryKey: [queryKey.atoms] });
    return atom;
  }, options);
}

export function useDeleteAtom() {
  return useMutation(async (uuid: string) => {
    await documentService.deleteAtom(uuid);
    queryClient.invalidateQueries({ queryKey: [queryKey.atoms] });
  });
}
export function useGetDocumentMerge(sourceDocUuid: string | null, targetDocUuid: string | null) {
  const query = useQuery(
    ['getDocumentMerge'],
    async () => {
      return await documentService.mergeDocuments(sourceDocUuid || '', targetDocUuid || '');
    },
    {
      staleTime: 1000 * 60,
      enabled: !!sourceDocUuid && !!targetDocUuid,
    }
  );

  return query;
}

export function useGetCodeGenDebug(
  codeBlockId: string,
  options?: UseQueryOptions<CodeGenDebug | null, any, CodeGenDebug | null, any>
) {
  const query = useQuery(
    [queryKey.codeGenDebug, codeBlockId],
    async () => {
      return await documentService.getCodeGenDebug(codeBlockId);
    },
    {
      staleTime: 1000 * 60,
      ...options,
    }
  );

  return query;
}

export function useGetTags(nodeUuid: string) {
  const query = useQuery(
    [queryKey.tags, nodeUuid],
    async () => {
      return await documentService.getTagsByNodeUuid(nodeUuid);
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return query;
}

export type CreateTagPayload = {
  nodeUuid: string;
  name: string;
};
export function useCreateTag(options?: UseMutationOptions<Tag, unknown, CreateTagPayload, unknown>) {
  return useMutation(async (data: CreateTagPayload) => {
    const tag = await documentService.createTag(data.nodeUuid, data.name);
    queryClient.invalidateQueries({ queryKey: [queryKey.tags] });
    return tag;
  }, options);
}

export type DeleteTagPayload = {
  tagUuid: string;
  nodeUuid: string;
};
export function useDeleteTag(options?: UseMutationOptions<boolean, unknown, DeleteTagPayload, unknown>) {
  return useMutation(async (data: DeleteTagPayload) => {
    const isSuccess = await documentService.deleteTagFromNode(data.tagUuid, data.nodeUuid);
    queryClient.invalidateQueries({ queryKey: [queryKey.tags] });
    return isSuccess;
  }, options);
}
